import {
  ClientAnalyticsEventType,
  FeatureGate,
  FeatureGateClickedSource,
  OrgRole,
} from "@hex/common";

import { trackEvent } from "./trackEvent";

export const trackFeatureGateClicked = ({
  feature,
  orgRole,
  source = FeatureGateClickedSource.FEATURE_DISABLED,
}: {
  feature: FeatureGate | FeatureGate[];
  source: FeatureGateClickedSource;
  orgRole?: OrgRole;
}): void => {
  trackEvent(ClientAnalyticsEventType.FEATURE_GATE_CLICKED, {
    featureGateType: feature
      ? Array.isArray(feature)
        ? feature
        : [feature]
      : [],
    triggeredFrom: source,
    orgRole,
  });
};
